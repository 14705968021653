.ui.secondary.menu .item {
  padding: .7em .7em;
}

.shadow {
  box-shadow: 0em 0em 2em 0em #ccc;
}

.banner {
  border-radius: 1em;
  padding: 1em
}

.banner * {
  font-size: 1.1em !important;
}

a {
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
}

.fade {
  opacity: .7 !important;
}

.fade:disabled {
  opacity: .5 !important;
}

.fade:hover {
  opacity: 1 !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: #4183c4;
  text-decoration: underline;
  cursor: pointer;
}

#legend {
  font-family: Arial, sans-serif;
  background: #fff;
  padding: 10px;
  margin: 10px;
  border: 3px solid #000;
}

#content img {
  max-width: 100%;
  box-shadow: 0px 0px 12px #aaa;
}

.assistant div.content {
  font-size: medium;
}

.assistant div.content li {
  margin: 0.5em 0;
}

html,
body, #app {
  height: 100%;
  min-height: 100%;
  margin: 0;
}

.presentation {
  display: flex;
  flex-flow: column;
  height: 100%;

}
.header {
  flex: 0 1 auto;
  font-size: 1em !important;
  margin-bottom: 0 !important;
}

.item {
  margin: 0 !important;
}

.main {
  flex: 1 1 auto;
  overflow: auto;
}

.footer {
  flex: 0 1 40px;
}

.assistant {
  display: flex;
  flex-direction: column;
}

#locator {
  flex: 0 1 auto;
  width: 20em;
  margin-left: 0 !important;
}
#dialog {
  flex: 1 1 auto;
}
#dialogWrapper {
  overflow:auto; display:flex; flex-direction:column-reverse;
}

#query {
  flex: 0 1 auto;
}

.h100 {height: 100%}

.information {
  background-color: white;
  padding: 0.5em;
  overflow-y: scroll;
}

@media screen and (orientation: portrait) {
  .information {
    bottom: 0 !important;
    /* Left should be (100 - width) / 2 */
    left: 0% !important;
    width: 100% !important;
    height: 40% !important;
  }
}

@media screen and (orientation: landscape) {
  .information {
    /* Top should be (100 - height) / 2 */
    top: 0% !important;
    left: 0 !important;
    width: 40% !important;
    height: 100% !important;
  }
  .narrowOnLandscape {
    width: 75%;
    margin: auto
  }
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.header * {
  font-size: 18px
}

#app > div > div.ui.secondary.header.menu > div > button {
  height: 2em;
  padding: 0.5em 2em;
  margin-top: 0.25em;
}

#app > div > div.ui.secondary.header.menu > div > div > div {
  font-size: 18px
}

.clickable {
  /* border: 1px solid black; */
  /* background-color: #ccf; */
  background: none;
  border-radius: .2em;
  /* box-shadow: .2em .2em .2em lightgray; */
  cursor: pointer;
}

.clickable:active {
  /* box-shadow: .1em .1em .1em lightgray; */
  transform: translate(.1em, .1em);
}

.clickable.disabled {
  filter: saturate(25%) brightness(1.5);
  cursor: default;
  display: none;
}

/* Prevent zooming on iphone */
input, select, textarea {
  font-size: 16px;
}
.noclick {
  cursor: default;
  pointer-events: none !important;
}